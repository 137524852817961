import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68')
];

export const server_loads = [0];

export const dictionary = {
		"/debug": [61],
		"/[[lang]]/(nru)/forgot-password": [15,[2]],
		"/[[lang]]/(nru)/forgot-password/confirm": [16,[2]],
		"/[[lang]]/keypairoom": [24,[6]],
		"/[[lang]]/keypairoom/regenerate": [25,[6]],
		"/[[lang]]/(nru)/login": [17,[2,3]],
		"/[[lang]]/(nru)/login/webauthn": [18,[2,3]],
		"/[[lang]]/logout": [26],
		"/[[lang]]/my": [27,[7],[8]],
		"/[[lang]]/my/certificates": [28,[7],[8]],
		"/[[lang]]/my/did": [29,[7],[8]],
		"/[[lang]]/my/folders": [30,[7],[8]],
		"/[[lang]]/my/multisignatures": [31,[7],[8]],
		"/[[lang]]/my/multisignatures/create": [33,[7],[8]],
		"/[[lang]]/my/multisignatures/create/participants": [34,[7],[8]],
		"/[[lang]]/my/multisignatures/create/review": [35,[7],[8]],
		"/[[lang]]/my/multisignatures/create/setup": [36,[7],[8]],
		"/[[lang]]/my/multisignatures/seals": [37,[7],[8]],
		"/[[lang]]/my/multisignatures/seals/[seal_id]": [38,[7],[8]],
		"/[[lang]]/my/multisignatures/[multisignature_id]": [32,[7],[8]],
		"/[[lang]]/my/organizations": [39,[7,9],[8]],
		"/[[lang]]/my/organizations/create": [53,[7,9],[8]],
		"/[[lang]]/my/organizations/join": [54,[7,9],[8]],
		"/[[lang]]/my/organizations/[id]": [40,[7,9,10],[8]],
		"/[[lang]]/my/organizations/[id]/credential-issuances": [41,[7,9,10],[8]],
		"/[[lang]]/my/organizations/[id]/credential-issuances/create": [44,[7,9,10],[8]],
		"/[[lang]]/my/organizations/[id]/credential-issuances/[issuance_id]": [42,[7,9,10,11],[8]],
		"/[[lang]]/my/organizations/[id]/credential-issuances/[issuance_id]/edit": [43,[7,9,10,11],[8]],
		"/[[lang]]/my/organizations/[id]/members": [45,[7,9,10],[8]],
		"/[[lang]]/my/organizations/[id]/microservices": [46,[7,9,10],[8]],
		"/[[lang]]/my/organizations/[id]/settings": [47,[7,9,10],[8]],
		"/[[lang]]/my/organizations/[id]/templates": [48,[7,9,10],[8]],
		"/[[lang]]/my/organizations/[id]/verification-flows": [49,[7,9,10],[8]],
		"/[[lang]]/my/organizations/[id]/verification-flows/create": [52,[7,9,10],[8]],
		"/[[lang]]/my/organizations/[id]/verification-flows/[verification_flow_id]": [50,[7,9,10,12],[8]],
		"/[[lang]]/my/organizations/[id]/verification-flows/[verification_flow_id]/edit": [51,[7,9,10,12],[8]],
		"/[[lang]]/my/profile": [55,[7],[8]],
		"/[[lang]]/my/signatures": [56,[7,13],[8]],
		"/[[lang]]/my/signatures/shared": [57,[7,13],[8]],
		"/[[lang]]/my/validate": [58,[7],[8]],
		"/[[lang]]/news": [59],
		"/[[lang]]/(nru)/organization-invite-[orgId]-[inviteId]-[email]-[[userId]]": [19,[2]],
		"/[[lang]]/(showcase)/organizations": [22,[4]],
		"/[[lang]]/(topbar)/pricing": [23,[5]],
		"/[[lang]]/(nru)/register": [20,[2]],
		"/[[lang]]/(nru)/reset-password-[token]": [21,[2]],
		"/tests/authorizations": [62],
		"/tests/feature-flags": [63],
		"/tests/forms": [64],
		"/tests/portal": [65],
		"/tests/record-form": [66],
		"/tests/records-manager": [67],
		"/tests/relations-manager": [68],
		"/[[lang]]/verify-email-[token]": [60],
		"/[[lang]]": [14]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';